import { Col, Row } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentSection from '../../ContentSection';
import DotCircleIcon from '../../../assets/icons/font-awesome/regular/chart-network.svg';
import ChartAreaIcon from '../../../assets/icons/font-awesome/solid/analytics.svg';
import DatabaseIcon from '../../../assets/icons/font-awesome/regular/mobile.svg';

function Features() {
  const { t } = useTranslation('BackLinkExplorer');

  const data = [
    {
      icon: DotCircleIcon,
      title: t('Features.feature1.title'),
      description: t('Features.feature1.description'),
    },
    {
      icon: ChartAreaIcon,
      title: t('Features.feature2.title'),
      description: t('Features.feature2.description'),
    },
    {
      icon: DatabaseIcon,
      title: t('Features.feature3.title'),
      description: t('Features.feature3.description'),
    },
  ];

  return (
    <ContentSection background={2}>
      <Row className="justify-content-md-center">
        {data.map((feature) => (
          <Col lg={4} key={feature.title}>
            <div className="svg-color-primary mb-3">
              <feature.icon height={48} />
            </div>

            <h2 className="gr-text-7 text-blackish-blue">{feature.title}</h2>

            <p className="gr-text-9 gr-color-blackish-blue-opacity-7 py-5">
              {feature.description}
            </p>
          </Col>
        ))}
      </Row>
    </ContentSection>
  );
}

export default Features;
