import React from 'react';
import { Row, Col } from 'react-bootstrap';

import CheckIcon from '../../../assets/icons/font-awesome/solid/check.svg';
import GetStartedButton from '../../GetStartedButton';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import ContentSection from '../../ContentSection';

const ContentStrategy = () => {
  const { t } = useTranslation('BackLinkExplorer');
  const title = t('ContentStrategy.title');

  const features = [
    t('ContentStrategy.feature1'),
    t('ContentStrategy.feature2'),
    t('ContentStrategy.feature3'),
    t('ContentStrategy.feature4'),
  ];

  return (
    <ContentSection>
      <Row
        className="align-items-center"
        itemScope={true}
        itemType="https://schema.org/ImageObject"
      >
        <Col
          lg="7"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="500"
          className="text-center"
        >
          <StaticImage
            src="../../../assets/image/backlink-explorer/content-strategy.png"
            width={550}
            sizes="(min-width: 550px) 550px, (min-width: 360px) 330px, 100vw"
            breakpoints={[330, 550]}
            placeholder="blurred"
            alt={title}
            className="shadow-1"
            itemProp="contentUrl"
          />
        </Col>

        <Col lg="5">
          <h2 className="title gr-text-4 mb-7 mt-7" itemProp="name">
            {title}
          </h2>

          <ul className="card-list list-style-check pl-0 mt-7 mt-lg-11">
            {features.map((feature, index) => (
              <li className="gr-text-9 ml-5" key={`feat-${index}`}>
                <span className="fa-svg-icon svg-color-primary mr-5">
                  <CheckIcon />
                </span>
                {feature}
              </li>
            ))}
          </ul>

          <GetStartedButton />
        </Col>
      </Row>
    </ContentSection>
  );
};

export default ContentStrategy;
