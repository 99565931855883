import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContentSection from '../../ContentSection';
import GetStartedButton from '../../GetStartedButton';

const CTA2 = () => {
  const { t } = useTranslation('BackLinkExplorer');
  const title = t('CTA2.title');
  const subtitle = t('CTA2.subtitle');

  return (
    <ContentSection>
      <Row className="align-items-center ">
        <Col lg="6">
          <div className="section-title cta-text pr-lg-5">
            <h2 className="gr-text-5 mb-7">{title}</h2>
            <p className="gr-text-8">{subtitle}</p>
          </div>
        </Col>

        <Col lg="6" xl="5" className="offset-xl-1 text-right">
          <GetStartedButton />
        </Col>
      </Row>
    </ContentSection>
  );
};

export default CTA2;
