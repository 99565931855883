import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTags from '../components/Seo/MetaTags';
import StructuredService from '../components/Seo/StructuredService';
import StructuredAggregateRating from '../components/Seo/StructuredAggregateRating';
import Hero from '../components/pages/BackLinkExplorer/Hero';
import Features from '../components/pages/BackLinkExplorer/Features';
import SEOAnalysis from '../components/pages/BackLinkExplorer/SEOAnalysis';
import LinkBuilding from '../components/pages/BackLinkExplorer/LinkBuilding';
import ContentStrategy from '../components/pages/BackLinkExplorer/ContentStrategy';
import CTA from '../components/pages/BackLinkExplorer/CTA';
import Pricing from '../components/Pricing';
import Testimonials from '../components/pages/BackLinkExplorer/Testimonials';
import CTA2 from '../components/pages/BackLinkExplorer/CTA2';

const BackLinkExplorer = () => {
  const { t } = useTranslation('BackLinkExplorer');

  return (
    <>
      <MetaTags title={t('seo.title')} description={t('seo.description')} />
      <StructuredService name="BackLink Explorer" />
      <StructuredAggregateRating
        name="SpySERP BackLink Explorer"
        description="SpySERP BackLink Explorer"
        makesOffer="SpySERP BackLink Explorer"
        knowsAbout="SpySERP BackLink Explorer"
        ratingValue={5}
        bestRating={5}
        ratingCount={24}
      />

      <Hero />
      <Features />
      <SEOAnalysis />
      <LinkBuilding />
      <ContentStrategy />
      <Pricing />
      <CTA />
      <Testimonials />
      <CTA2 />
    </>
  );
};

export default BackLinkExplorer;
