import Hero from '../../Hero';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PanelReact from '@spyserp/panel-react';
import useSiteMetadata from '../../../hooks/useSiteMetadata';

const {
  BacklinkExplorerStore,
  appLocale,
  SpyserpIOLink,
  TrpcDecorator,
  ThemeDecorator,
  BacklinkExplorer,
  SettingsProvider,
} = PanelReact;

function BackLinkExplorerHero() {
  const siteMeta = useSiteMetadata();
  const [isServer, setIsServer] = React.useState(true);
  const [isDomainSet, setIsDomainSet] = React.useState(false);
  const { apiDomain, apiSocketUrl, apiUrl, appBaseUrl, spySerpApp } = siteMeta;
  const { socketIoJsFile } = spySerpApp;

  const { t, i18n } = useTranslation('BackLinkExplorer');
  const { language } = i18n;

  const settings = {
    isLanding: true,
    apiDomain: apiDomain,
    socketURL: apiSocketUrl,
    apiURL: apiUrl,
  };

  const ioLink = SpyserpIOLink({
    apiURL: apiUrl,
    socketURL: apiSocketUrl,
    socketIOScriptURL: `${appBaseUrl}/${socketIoJsFile}`,
    forceSocketIOScriptLoad: true,
  });

  const title = t('Hero.title');
  const subtitle = t('Hero.subtitle');

  useEffect(() => {
    appLocale.set(language === 'ru' ? language : 'en');
  }, [language]);

  useEffect(() => {
    setIsServer(false);
    const unsubscribe = BacklinkExplorerStore.store.listen((data) => {
      setIsDomainSet(Boolean(data.domain));
    });

    return () => {
      unsubscribe();
      BacklinkExplorerStore.resetState({ updateHash: false });
    };
  }, []);

  return (
    <Hero title={title} subtitle={subtitle} hideSignupButton>
      <div className="px-0">
        {isServer ? null : (
          <SettingsProvider value={settings}>
            <TrpcDecorator trpcLink={ioLink}>
              <ThemeDecorator>
                <BacklinkExplorer
                  sx={{ display: 'block', p: isDomainSet ? 0 : 3 }}
                  scrollContainerSX={{
                    position: 'relative',
                    backgroundColor: '#fcfdfe',
                  }}
                  searchBoxProps={{
                    hideDescription: true,
                    sx: {
                      pt: 3,
                      pb: { xs: 1, sm: 0 },
                      maxWidth: '650px',
                      margin: '0 auto',
                    },
                  }}
                />
              </ThemeDecorator>
            </TrpcDecorator>
          </SettingsProvider>
        )}
      </div>
    </Hero>
  );
}

export default BackLinkExplorerHero;
