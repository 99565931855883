import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContentSection from '../../ContentSection';
import { StaticImage } from 'gatsby-plugin-image';

const Testimonials = () => {
  const { t } = useTranslation('BackLinkExplorer');
  const title = t('Testimonials.title');

  const testimonialOne = {
    text: t('Testimonials.item1.text'),
    name: t('Testimonials.item1.name'),
    job: t('Testimonials.item1.job'),
  };

  const testimonialTwo = {
    text: t('Testimonials.item2.text'),
    name: t('Testimonials.item2.name'),
    job: t('Testimonials.item2.job'),
  };

  const testimonialTree = {
    text: t('Testimonials.item3.text'),
    name: t('Testimonials.item3.name'),
    job: t('Testimonials.item3.job'),
  };

  const ImageOne = () => (
    <StaticImage
      src="../../../assets/image/user.svg"
      alt={testimonialOne.name}
      layout="fixed"
      width={48}
    />
  );

  const ImageTwo = () => (
    <StaticImage
      src="../../../assets/image/user.svg"
      alt={testimonialTwo.name}
      layout="fixed"
      width={48}
    />
  );

  const ImageThree = () => (
    <StaticImage
      src="../../../assets/image/user.svg"
      alt={testimonialTree.name}
      layout="fixed"
      width={48}
    />
  );

  const testimonials = [testimonialOne, testimonialTwo, testimonialTree];

  return (
    <ContentSection title={title} background={2}>
      <Row className="align-items-center justify-content-around d-flex align-items-stretch">
        {testimonials.map(({ name, text, job }, index) => {
          return (
            <Col
              key={`testimonial-${index}`}
              lg="4"
              md="6"
              sm="9"
              className="mb-16"
            >
              <div className="h-100 d-flex flex-column px-5">
                <p className="mb-0 gr-text-10 gr-text-color">“{text}”</p>

                <div className="media pt-9 pt-lg-12 d-flex mt-auto">
                  <div className="circle-md mr-7">
                    {index === 0 && <ImageOne />}
                    {index === 1 && <ImageTwo />}
                    {index === 2 && <ImageThree />}
                  </div>

                  <div className="text-left align-self-center">
                    <div className="h4 gr-text-9 mb-0">{name}</div>
                    <span className="gr-text-11 gr-text-color-opacity">
                      {job}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </ContentSection>
  );
};

export default Testimonials;
